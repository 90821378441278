









































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'

@Component({
  components: {
  },
})
export default class ContactConfirm extends Vue {
  contacts: any[] = []

  mounted () {
    firebase.firestore().collection('contacts').where('complete', '==', false).orderBy('createTime', 'desc').get().then(async (datas) => {
      for (let i=0;i < datas.size; i++) {
      let contact = datas.docs[i]
        let contactData = contact.data()!
        contactData.id = contact.id
        if (contactData.files && contactData.files.length != 0) {
          contactData.fileUrls = []
          for (let file of contactData.files) {
            let url = await firebase.storage().ref(file).getDownloadURL()
            contactData.fileUrls.push(url)
          }
          this.contacts.push(contactData)
        } else {
          this.contacts.push(contactData)
        }
      }
    }).catch(() => {
      this.$router.push('/404')
    })
  }

  complete(contact: any, index: number) {
    if (confirm('本当によろしいですか'))　{
      firebase.firestore().collection('contacts').doc(contact.id)
        .update({complete: true})
      this.contacts.splice(index, 1)
    }
  }

}
